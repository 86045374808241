<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg">
        <img class="w-full sm:h-80" :src="ProductDetail.img" />

        <div class="grid grid-cols-1 gap-2 lg:grid-cols-3">
          <div class="col-span-1">
            <img
              :src="ProductDetail.NFT"
              class="w-40 sm:w-60 rounded-xl border-4 border-blue-500 border-opacity-50 ml-3"
              style="margin-top: -100px"
            />
          </div>
          <div class="col-span-2">
            <div class="p-5">
              <h3
                class="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to-green-200"
              >
                {{ ProductDetail.brand }}
              </h3>

              <h3
                class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to-green-200"
              >
                Earn NFTs for learning how to use the most popular apps on
                BEP-20
              </h3>

              <br />

              <h3
                class="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to-green-200"
              >
                <a class="text-gray-400 text-sm">Owned by </a>auto Rebalance
              </h3>
            </div>
          </div>
        </div>

        <div class="w-full sm:p-0 p-2">
          <div
            class="bg-gray-800 p-5 rounded border-2 border-blue-500 border-opacity-50"
          >
            <div class="grid grid-cols-4 gap-2 lg:grid-cols-4">
              <div class="flex flex-col items-center">
                <h3 class="text-xl font-bold text-blue-300">10000</h3>
                <h3 class="text-sm font-bold text-gray-300">Items</h3>
              </div>

              <div class="flex flex-col items-center">
                <h3 class="text-xl font-bold text-blue-300">467</h3>
                <h3 class="text-sm font-bold text-gray-300">Owners</h3>
              </div>

              <div class="flex flex-col items-center">
                <h3 class="text-xl font-bold text-blue-300">10000</h3>
                <h3 class="text-sm font-bold text-gray-300">Floor price</h3>
              </div>

              <div class="flex flex-col items-center">
                <span class="flex">
                  <span
                    class="mt-1 animate-ping absolute inline-flex w-5 h-5 rounded-full bg-yellow-400 opacity-75"
                  ></span>

                  <div class="flex">
                    <img src="/tokens/BNB.png" class="h-5 mt-1" />
                    <h3 class="text-xl font-bold text-blue-300 ml-1">
                      {{ ProductDetail.price }}
                    </h3>
                  </div>
                </span>

                <h3 class="text-sm font-bold text-gray-300">price</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 p-4 pt-5 pb-5"
      >
        <div class="text-gray-200">
          <a
            v-on:click="$router.go(-1)"
            class="flex items-center space-x-2 text-center cursor-pointer"
            ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
          >
        </div>
      </div>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 p-4 pt-10"
      >
        <div class="grid grid-cols-1 gap-2 lg:grid-cols-3">
          <div class="col-span-1">
            <img
              :src="ProductDetail.NFT"
              class="w-30 rounded-xl border-4 border-blue-500 border-opacity-50"
              style="margin-top: -40px"
            />
          </div>
          <div class="col-span-2">
            <div class="ml-5">
              <h3
                class="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to-green-200"
              >
                {{ ProductDetail.name }}
              </h3>

              <h3
                class="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to-green-200"
              >
                Earn NFTs for learning how to use the most popular apps on
                BEP-20
              </h3>

              <br />

              <h3
                class="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to-green-200"
              >
                <a class="text-gray-400 text-sm">Owned by </a>auto Rebalance
              </h3>

              <h3
                class="mt-3 text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to-green-200"
              >
                Price
              </h3>

              <div class="flex">
                <img src="/tokens/BNB.png" class="h-9 mt-4" />
                <h3
                  v-if="amount != 0"
                  class="text-3xl font-extrabold m-4 text-gray-200"
                >
                  {{ ProductDetail.price * amount }}
                  <a class="text-lg text-gray-400">
                    ( {{ 15.5823 * amount }} USD )
                  </a>
                </h3>

                <h3 v-else class="text-3xl font-extrabold m-4 text-gray-200">
                  {{ ProductDetail.price }}
                  <a class="text-lg text-gray-400"> ( {{ 15.5823 }} USD ) </a>
                </h3>
              </div>

              <h3
                class="text-xs ml-2 font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to-green-200"
              >
                Available in wallet
                <a class="text-lg">
                  {{ parseFloat(getBalance["BNB"].balance).toFixed(4) }}
                </a>
                BNB
              </h3>

              <h3
                class="mt-5 text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to-green-200"
              >
                <i class="fas fa-info-circle" style="font-size: 24px"></i>

                Sale ends February 20, 2023 at 11:24:09 AM
              </h3>
            </div>
          </div>
        </div>

        <div class="mt-10 mb-20">
          <div class="grid grid-cols-1 gap-2 lg:grid-cols-3">
            <div class="col-span-1 flex flex-col items-center">
              <div
                class="inline-block w-full mt-1 p-px bg-gradient-to-r from-blue-700 to-green-500 rounded"
              >
                <div
                  class="inline-block w-full text-sm border-none rounded bg-gray-900"
                >
                  <div class="px-3 py-3 sm:py-3">
                    <div class="flex flex-col items-center">
                      <img class="mt-1" :src="ProductDetail.img" />
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-2">
              <div
                class="inline-block h-full w-full mt-1 p-px bg-gradient-to-r from-blue-700 to-green-500 rounded"
              >
                <div
                  class="inline-block h-full w-full text-gray-300 text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900"
                >
                  <br />
                  <div class="">
                    <div>
                      <div class="grid grid-cols-1 gap-y-3">
                        <div class="flex flex-col space-y-8 space-y-0">
                          <div class="">
                            <div class="flex font-semibold">
                              <span
                                class="flex-grow leading-tight text-gray-500 sm:text-base"
                                style="font-size: 14px"
                              >
                                Available in wallet
                              </span>
                              <span
                                class="flex-none leading-tight whitespace-nowrap text-shadow-white text-xs sm:text-base"
                                ><span style="font-size: 18px">
                                  {{
                                    parseFloat(
                                      getBalance["BNB"].balance
                                    ).toFixed(4)
                                  }}
                                  BNB</span
                                ></span
                              >
                            </div>

                            <div class="grid grid-cols-3 lg:grid-cols-3 mt-">
                              <div class="flex flex-col items-end">
                                <button
                                  class="rounded-full h-10 w-10 flex items-center justify-center bg-blue-700"
                                  @click="addProducts('remove')"
                                >
                                  <i
                                    class="fas fa-minus"
                                    style="font-size: 24px"
                                  ></i>
                                </button>
                              </div>
                              <div class="flex flex-col items-center text-2xl">
                                {{ amount }}
                              </div>
                              <div>
                                <button
                                  class="rounded-full h-10 w-10 flex items-center justify-center bg-blue-700"
                                  @click="addProducts('add')"
                                >
                                  <i
                                    class="fas fa-plus"
                                    style="font-size: 24px"
                                  ></i>
                                </button>
                              </div>
                            </div>
                            <div
                              v-if="
                                amount != '0' &&
                                ProductDetail.brand == 'cryptonia'
                              "
                              class="mt-5 text-lg"
                            >
                              Please enter delivery address to product

                              <div class="mt-2">
                                <div
                                  class="flex justify-center py-1 sm:py-3 px-3 rounded-xl bg-gray-700"
                                >
                                  <input
                                    v-model="name"
                                    placeholder="Full name"
                                    class="flex-grow outline-none text-sm bg-transparent"
                                    type="string"
                                  />

                                  <span class="text-lg"> Name</span>
                                </div>
                              </div>

                              <div class="mt-2">
                                <div
                                  class="flex justify-center py-1 sm:py-3 px-3 rounded-xl bg-gray-700"
                                >
                                  <input
                                    v-model="phone"
                                    placeholder="Phone number"
                                    class="flex-grow outline-none text-sm bg-transparent"
                                    type="number"
                                  />

                                  <span class="text-lg"> Phone</span>
                                </div>
                              </div>

                              <div class="mt-2">
                                <div
                                  class="flex justify-center py-1 sm:py-3 px-3 rounded-xl bg-gray-700"
                                >
                                  <input
                                    v-model="address"
                                    placeholder="Address"
                                    class="flex-grow outline-none text-sm bg-transparent"
                                    type="string"
                                  />

                                  <span class="text-lg"> Address</span>
                                </div>
                              </div>
                            </div>

                            <div class="flex justify-center py-3 mt-2">
                              <button
                                class="w-50 h-10 flex flex-col items-center flex-none text-sm px-6 py-2 border rounded-xl border-none bg-gradient-to-r from-blue-700 to-green-600"
                                @click="BuyNow()"
                              >
                                Buy Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <!-- <StickFooter /> -->
      </footer>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import LiveTx from "@/components/LiveTx.vue";
import { mapGetters, mapActions } from "vuex";
import liquidity from "@/api/liquidity";
import nativeToast from "native-toast";
import func from "@/widgets/func";
import NFTs from "@/api/NFTs";
import Swal from "sweetalert2";

// import { products, products_2, main_product } from "../../products";

export default {
  data() {
    return {
      mode: 1, // 1 = buy
      price: null,
      priceimpact: null,
      average: null,

      token0: {
        img: "/tokens/BNB.png",
        balance: "-",
        chain: "BNB",
        coin: "BNB",
      },
      token1: {
        img: "/tokens/HBX.png",
        balance: "-",
        chain: "BNB",
        coin: "HBX",
      },
      loading: false,
      liquidity: null,
      symbol: null,
      id: this.$route.params.id,
      products: [],
      products_2: [],
      ProductDetail: {},
      amount: 0,
      phone: null,
      name: null,
      address: null,
    };
  },
  components: {
    LiveTx,
    ConnectWallet,
    StickFooter,
  },
  computed: mapGetters([
    "getBalance",
    "getCurrency",
    "getMarket",
    "getUser",
    "getTokenBalance",
  ]),

  methods: {
    ...mapActions(["fetchAssets", "fetchBalance", "fetchMarket"]),

    fixed(a) {
      return func.fixed(a);
    },
    addProducts(typeProduct) {
      if (typeProduct == "add") {
        this.amount = parseFloat(this.amount) + 1;
      } else {
        if (this.amount > 0) {
          this.amount = parseFloat(this.amount) - 1;
        }
      }
    },

    getNFTs() {
      NFTs.list()
        .then((res) => {
          this.ProductDetail = res.data.nfts.filter((p) => {
            return p._id == this.id;
          })[0];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    BuyNow() {
      if (parseFloat(this.amount) > 0) {
        NFTs.buy({
          address: this.getUser.address,
          nft_id: this.ProductDetail._id,
          amount: this.amount,
          name: this.name,
          phnoe: this.phone,
          delivery_address: this.address,
        })
          .then((res) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "You buy success",
              showConfirmButton: false,
              timer: 1400,
            });

            setTimeout(() => {
              this.$router.push({ path: "/collections" });
            }, "1500");
          })
          .catch((error) => {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "You buy error",
              showConfirmButton: false,
              timer: 1400,
            });
          });
      }
    },
  },
  async created() {
    this.symbol = "BNB";

    this.getNFTs();

    // this.getTokenBalance();
    this.fetchBalance();
    // this.fetchAssets();
  },
  updated() {},
};
</script>
